
<template>
	<div class="page">
		<div class="huaxue_analysis_sys relative">
			<div class="img"><img src="../assets/static/jiansuo_bg.png"/></div>
			<div class="sys_inputdiv">
				<div class="title fontsize24">专利检索分析系统</div>
				<div class="inputdiv">
					<input class="fontsize12" v-model="keyword" placeholder="请输入检索内容"/>
					<div class="seabtn" @click="$util.routerPath('/analysis_sys_page',{key:keyword})"><i class="el-icon-search"></i></div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 专题数据库 -->
		<div class="huaxue_analysis_box w1260">
			<div class="database_box" v-for="(item,index) in databaseList" :key="index">
				<div class="img"><img src="../assets/static/imagebg.png" /></div>
				<div class="database_con" @click="$util.routerPath('/analysis_sys_page',{typeId:item.id})">
					<div class="title fontsize22">{{item.name}}</div>
					<div class="more fontsize16" v-html="'去查看 >'"></div>
				</div>
			</div>
		</div>
		<!-- 专题数据库 -->
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			databaseList:[8,8,8,8],
			keyword:"",
		};
	},
	mounted: function() {
		this.pagedatabaseType()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取专题数据库
		pagedatabaseType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888,
				state:0,//	状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/patent/pageType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.databaseList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>